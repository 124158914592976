import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import DownloadReportButton from '@components/form/DownloadReportButton'
import ToggleSwitch from '@components/form/ToggleSwitch'
import PruningReport from '@components/pdf/pruning-report/PruningReport'
import SpurPruningReportPdf from '@components/pdf/pruning-report/spur/SpurPruningReportPdf'
import BowPrunedSection from '@components/sections/pruning-summary/BowPrunedSection'
import CanePrunedSection from '@components/sections/pruning-summary/CanePrunedSection'
import SpurPrunedSection from '@components/sections/pruning-summary/SpurPrunedSection'
import { useGetBlockReportsByPruningStyle } from '@hooks/useGetBlockReportsByPruningStyle'
import { pdf } from '@react-pdf/renderer'
import useVineyardStore from '@stores/vineyard'
import { ReportLevelsEnum, ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { localStorageGetItem } from '@utils/utility-fns'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'

const PruningSummaryPage = () => {
  const loadInitialState = () => {
    const savedState = localStorageGetItem('statsBy')
    return savedState === 'cane' ? 'cane' : 'vine'
  }
  const [statsBy, setStatsBy] = useState<'vine' | 'cane'>(loadInitialState)

  useEffect(() => {
    localStorage.setItem('statsBy', statsBy)
  }, [statsBy])

  const [activePruningStyle] = useVineyardStore((s) => [s.activePruningStyle])

  const condition = {
    cane: activePruningStyle === 'cane',
    bow: activePruningStyle === 'bow',
    spur: activePruningStyle === 'spur',
  }

  const { data, count, isFetching } = useGetBlockReportsByPruningStyle({
    reportType: ReportTypeEnum.Pruning,
    reportLevels: [ReportLevelsEnum.Block, ReportLevelsEnum.Rows],
  })

  const downloadPdf = async () => {
    const element = document.getElementById('cane-pruning-report-btn')
    try {
      element?.setAttribute('style', 'cursor: wait')
      element?.setAttribute('disabled', 'true')
      const fileName = 'Cane Pruning Report.pdf'
      const blob = await pdf(<PruningReport reports={data.cane} />).toBlob()
      saveAs(blob, fileName)
    } catch (error) {
      console.error('Failed to download PDF:', error)
    } finally {
      element?.removeAttribute('style')
      element?.removeAttribute('disabled')
    }
  }

  const downloadSpurPdf = async () => {
    const element = document.getElementById('spur-pruning-report-btn')
    try {
      element?.setAttribute('style', 'cursor: wait')
      element?.setAttribute('disabled', 'true')
      const fileName = 'Spur Pruning Report.pdf'
      const blob = await pdf(<SpurPruningReportPdf reports={data.spur} />).toBlob()
      saveAs(blob, fileName)
    } catch (error) {
      console.error('Failed to download PDF:', error)
    } finally {
      element?.removeAttribute('style')
      element?.removeAttribute('disabled')
    }
  }

  return (
    <>
      <PruningStyleOptionsWrapper
        blocksCountByPruningStyle={count}
        sx={{ pb: 2 }}
        endComponent={
          activePruningStyle === 'cane' ? (
            <div style={{ display: 'flex', gap: 8, alignItems: 'end' }}>
              <DownloadReportButton
                id="cane-pruning-report-btn"
                label="pruning_report"
                onClick={downloadPdf}
                disabled={isFetching || data.cane.length === 0}
              />

              <ToggleSwitch
                options={[
                  { label: 'stats_by_vine', value: 'vine' },
                  { label: 'stats_by_cane', value: 'cane' },
                ]}
                value={statsBy}
                onClick={(val) => setStatsBy(val as 'vine' | 'cane')}
                optionWidth={120}
              />
            </div>
          ) : activePruningStyle === 'spur' ? (
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <DownloadReportButton
                id="spur-pruning-report-btn"
                label="pruning_report"
                onClick={downloadSpurPdf}
                disabled={isFetching || data.spur.length === 0}
              />
            </div>
          ) : null
        }
      />
      {condition.cane && (
        <SummaryPageWrapper dataLength={data.cane.length} isLoading={isFetching}>
          <CanePrunedSection data={data.cane} statsBy={statsBy} />
        </SummaryPageWrapper>
      )}
      {condition.bow && (
        <SummaryPageWrapper dataLength={data.bow.length} isLoading={isFetching}>
          <BowPrunedSection data={data.bow} />
        </SummaryPageWrapper>
      )}
      {condition.spur && (
        <SummaryPageWrapper dataLength={data.spur.length} isLoading={isFetching}>
          <SpurPrunedSection data={data.spur} />
        </SummaryPageWrapper>
      )}
    </>
  )
}

export default PruningSummaryPage
