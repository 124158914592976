import { useSnackbar } from 'notistack'

import * as Sentry from '@sentry/react'

export function useHandleAxiosError() {
  const { enqueueSnackbar } = useSnackbar()

  return (error: any, msg?: string) => {
    if (process.env.NODE_ENV === 'development') console.error(error)
    if (process.env.NODE_ENV === 'production') {
      if (!error?.response) return
      else {
        const { status, data } = error.response
        if (status === 401) {
          if (data?.message === 'Invalid Credentials') Sentry.captureException(error)
          else console.error(error)
        } else {
          Sentry.captureException(error)
        }
      }
    }

    if (error) {
      if (!error?.response) return
      else {
        const { status, data } = error.response
        if (status === 401) {
          if (data?.message === 'Invalid Credentials') {
            enqueueSnackbar(`Error ${status}: ${msg ?? error.message}`)
          } else {
            enqueueSnackbar(`Error ${status}`)
          }
        } else {
          enqueueSnackbar(`Error ${status}: ${msg ?? error.message}`)
        }
      }
    }
  }
}
