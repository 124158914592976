import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro'
import { PruningStyleUnion } from '@typings/dtos/block'
import { createFormatter, formatISO_ } from '@utils/utility-fns'
import { t } from 'i18next'

export const landCols = (pruningStyle: PruningStyleUnion): GridColDef[] => {
  const sharedCols: GridColDef[] = [
    {
      field: 'scanDate',
      headerName: 'Date',
      valueFormatter: (value) => formatISO_(value, 'd MMM yyyy'),
      type: 'date',
      width: 110,
    },
    { field: 'vineyardName', headerName: t('vineyard'), width: 150, valueFormatter: createFormatter() },
    { field: 'blockName', headerName: t('block'), width: 150, valueFormatter: createFormatter() },
    { field: 'rowNum', headerName: t('row'), width: 70, type: 'number' },
  ]

  const postsCols: GridColDef[] = [
    {
      field: 'pWooden',
      headerName: t('wooden'),
      width: 130,
      valueFormatter: createFormatter(),
    },
    {
      field: 'pMetal',
      headerName: t('metal'),
      width: 130,
      valueFormatter: createFormatter(),
    },
    {
      field: 'pMissing',
      headerName: t('missing'),
      width: 150,
      valueFormatter: createFormatter(),
    },
    {
      field: 'pUnknown',
      headerName: t('unknown'),
      width: 140,
      valueFormatter: createFormatter(),
    },
  ]

  const bowCols: GridColDef[] = [
    {
      field: 'vYoung',
      headerName: t('young'),
      width: 110,
    },
    {
      field: 'vMissing',
      headerName: t('missing'),
      width: 110,
    },
    {
      field: 'vNormal',
      headerName: t('normal'),
      width: 110,
    },
    {
      field: 'vUnknown',
      headerName: t('unknown'),
      width: 110,
    },
    {
      field: 'vBadQuality',
      headerName: t('low_quality'),
      width: 110,
    },
  ]

  const spurCols: GridColDef[] = [
    {
      field: 'missingCordon1',
      headerName: '1',
      width: 150,
    },
    {
      field: 'missingCordon2',
      headerName: '2',
      width: 150,
    },
  ]

  const caneCols: GridColDef[] = [
    {
      field: 'vDead',
      headerName: `0 ${t('canes')}/${t('dead')}`,
      width: 130,
    },
  ]

  const colsByPruningStyle: Record<PruningStyleUnion, GridColDef[]> = {
    bow: [...sharedCols, ...bowCols],
    spur: [...sharedCols, ...bowCols, ...spurCols, ...postsCols],
    cane: [...sharedCols, ...bowCols, ...caneCols, ...postsCols],
  }

  return colsByPruningStyle[pruningStyle]
}
export const landColsGrouping = (): GridColumnGroupingModel => [
  {
    groupId: t('vine_status'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [
          { field: 'vYoung' },
          { field: 'vMissing' },
          { field: 'vNormal' },
          { field: 'vDead' },
          { field: 'vUnknown' },
          { field: 'vBadQuality' },
        ],
      },
    ],
  },
  {
    groupId: t('pruning_status'),
    headerAlign: 'center',

    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('missing_cordons'),
        headerAlign: 'center',

        headerClassName: 'datagrid-mid-header',
        children: [{ field: 'missingCordon1' }, { field: 'missingCordon2' }],
      },
    ],
  },
  {
    groupId: t('post_status'),
    headerAlign: 'center',
    headerClassName: 'datagrid-top-header',
    children: [
      {
        groupId: t('statistics'),
        headerAlign: 'center',
        headerClassName: 'datagrid-mid-header',
        children: [{ field: 'pWooden' }, { field: 'pMetal' }, { field: 'pMissing' }, { field: 'pUnknown' }],
      },
    ],
  },
]
