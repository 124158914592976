import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import useVineyardStore from '@stores/vineyard'
import { localStorageGetItem } from '@utils/utility-fns'
import { useEffect } from 'react'

type ExcludeReportsCheckboxProps = {
  compact?: boolean
}

const ExcludeReportsCheckbox = ({ compact }: ExcludeReportsCheckboxProps) => {
  const [isChecked, setIsChecked] = useVineyardStore((s) => [s.excludeNonCorrectedReports, s.setExcludeNonCorrectedReports])

  const { t } = useTranslate()

  // Initialize state from localStorage only once
  useEffect(() => {
    const localStorageValue = localStorageGetItem('excludeNonCorrectedReports', 'false')
    setIsChecked(localStorageValue === 'true')
  }, [])

  // Sync the state with localStorage whenever isChecked changes
  useEffect(() => {
    if (isChecked !== undefined) localStorage.setItem('excludeNonCorrectedReports', isChecked.toString())
  }, [isChecked])

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'grey.400',
        borderRadius: compact ? '2px' : '4px',
        padding: compact ? '0 7px 0 10px' : '0 10px',
        display: 'flex',
        alignItems: 'center',
        bgcolor: '#FFFFFF',
        cursor: 'pointer',
        ':hover': { borderColor: 'grey.800' },
        ':active': { borderColor: 'primary.main' },
      }}
      onClick={() => setIsChecked(!isChecked)}
    >
      {compact ? (
        <label style={{ cursor: 'pointer', userSelect: 'none' }}>
          {t('exclude')} {t('non_corrected_reports')}
        </label>
      ) : (
        <label style={{ fontSize: 13, fontWeight: 500, color: '#525252', lineHeight: 1, cursor: 'pointer', userSelect: 'none' }}>
          {t('exclude')}
          <br />
          {t('non_corrected_reports')}
        </label>
      )}
      <input
        type="checkbox"
        style={{ marginLeft: compact ? 12 : 16, cursor: 'pointer', minHeight: compact ? 14 : 16, minWidth: compact ? 14 : 16 }}
        checked={!!isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
    </Box>
  )
}

export default ExcludeReportsCheckbox
