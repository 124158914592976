import Iconify from '@components/data-display/Iconify'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import * as Sentry from '@sentry/react'
import useAuthStore from '@stores/auth'
import { useMutation } from '@tanstack/react-query'
import { LoginDto } from '@typings/dtos/user'
import { getLandingPageBySeason } from '@utils/utility-fns'
import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

const Auth = () => {
  const [login, setLogin] = useState<LoginDto>({ email: '', password: '' })
  const [viewPassword, setViewPassword] = useState(false)

  const [user, setUser, isAuthenticated, setUserSettings] = useAuthStore((s) => [
    s.user,
    s.setUser,
    s.isAuthenticated,
    s.setUserSettings,
  ])
  const navigate = useNavigate()
  const handleAxiosError = useHandleAxiosError()

  const { mutate, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: () => axios.post(import.meta.env.VITE_CROPSY_BASE_URL + '/login', login),
    onSuccess: (res) => {
      const { data } = res
      setUser(data)
      setUserSettings(data.userSettings)
      Sentry.setUser({ email: data.email, fullName: data?.name })

      const landingPage = '/' + getLandingPageBySeason(data.countryCode)
      navigate(landingPage)
    },
    onError: (error) => handleAxiosError(error, 'User authentication failed'),
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutate()
  }

  useEffect(() => {
    if (isAuthenticated()) {
      const landingPage = '/' + getLandingPageBySeason(user?.countryCode) + '/reporting'
      navigate(landingPage)
    }
  }, [isAuthenticated, navigate])

  return (
    <>
      <Helmet>
        <title>Login - Osiris | Cropsy Technologies</title>
        <link rel="canonical" href="https://osiris.cropsy.tech/auth" />
        <meta name="description" content="Login to access your account on Osiris | Cropsy Technologies" />
        <meta property="og:title" content="Login - Osiris" />
        <meta property="og:description" content="Login to access your account on Osiris | Cropsy Technologies" />
        <meta property="og:url" content="https://osiris.cropsy.tech/auth" />
        <meta property="og:type" content="website" />
        <meta name="keywords" content="login, Osiris, Cropsy Technologies, account access" />
        <meta name="author" content="Cropsy Technologies" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="robots" content="index, follow" />
        <meta property="og:image" content="https://www.osiris.cropsy.tech/logos/cropsy-logo-text.svg" />
      </Helmet>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100dvh" pb={10}>
        <div style={{ display: 'grid', height: 300 }}>
          <Box component="img" src="/logos/cropsy-logo-icon.svg" alt="Cropsy Logo" sx={{ width: 180 }} />
          <Box component="img" src="/logos/cropsy-logo-text.svg" alt="Cropsy Logo" sx={{ width: 180, mb: 8, mt: 2 }} />
        </div>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'grid', gap: 2, width: '100%', maxWidth: 340 }}>
          <TextField
            id="email"
            type="email"
            variant="filled"
            label="Email"
            required
            autoFocus
            value={login.email}
            onChange={(e) => setLogin({ ...login, email: e.target.value })}
          />
          <TextField
            id="password"
            type={viewPassword ? 'text' : 'password'}
            variant="filled"
            label="Password"
            required
            value={login.password}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
            sx={{ color: 'primary.dark' }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setViewPassword((prev) => !prev)}>
                  {viewPassword ? (
                    <Iconify icon="material-symbols:visibility" />
                  ) : (
                    <Iconify icon="material-symbols:visibility-off" />
                  )}
                </IconButton>
              ),
            }}
          />
          <Button type="submit" variant="contained" disabled={isPending}>
            Login
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Auth
