import { Text, View } from '@react-pdf/renderer'
import { BlockData } from '@typings/dtos/block-report/models'
import { fmtNumToThousandSepSingleDecOrZero } from '@utils/utility-fns'

type SpurOverallStatsProps = {
  blockData: BlockData | undefined
}

const SpurOverallStats = ({ blockData }: SpurOverallStatsProps) => {
  if (!blockData) return null

  const Tile = ({ label, value }: { label: string; value: string | number }) => (
    <View style={{ border: `1px solid #71717A`, borderRadius: 2 }}>
      <View style={{ backgroundColor: '#71717A', padding: '4px 6px 6px 6px', color: '#FFFFFF' }}>
        <Text>{label}</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 4,
          padding: '6px 2px 6px 6px',
          justifyContent: 'space-between',
          fontWeight: 900,
          letterSpacing: 0.4,
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          backgroundColor: '#FFFFFF',
        }}
      >
        <Text style={{ fontSize: 11, fontWeight: 900 }}>{value}</Text>
      </View>
    </View>
  )

  return (
    <View style={{ backgroundColor: '#F5F5F5', height: '100%', width: 150, padding: 6, borderRadius: 2 }}>
      <Text>OVERALL PRUNING STATS</Text>

      <View style={{ borderBottomWidth: 1, borderBottomColor: '#FFF', marginBottom: 16, marginTop: 8 }} />

      <View style={{ marginHorizontal: 'auto', gap: 14 }}>
        <Tile label="Buds Per Vine (Mean)" value={fmtNumToThousandSepSingleDecOrZero(blockData.budsPerVineMean)} />
        <Tile
          label="Buds Per Spur Per Vine (Mean)"
          value={fmtNumToThousandSepSingleDecOrZero(blockData.budsPerSpurPerVineMean)}
        />
        <Tile label="Spurs Per Vine (Mean)" value={fmtNumToThousandSepSingleDecOrZero(blockData.spursPerVineMean)} />
      </View>
    </View>
  )
}

export default SpurOverallStats
