import Iconify from '@components/data-display/Iconify'
import UserSettingToggleGroup from '@components/form/UserSettingsToggleGroup'
import ChangePassword from '@components/sections/ChangePassword'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { IOption } from '@typings/common'
import { useEffect, useState } from 'react'

export type UserSettingsStorage = {
  af: 'enable' | 'disable' // af: auto filter
  afDateRange: '1' | '2' | '3' | '4'
  lp: 'enable' | 'disable' // lp: landing page
  lpSection: 'health' | 'yield' | 'pruning' | 'land'
  lpPage: 'map' | 'summary' | 'reporting'
}

const SettingsPage = () => {
  const [tab, setTab] = useState('auto-filter')
  const [storage, setStorage] = useState<UserSettingsStorage>({
    af: 'disable',
    afDateRange: '4',
    lp: 'disable',
    lpSection: 'health',
    lpPage: 'reporting',
  })

  const { t } = useTranslate()

  const options = [
    { label: 'auto_filter', value: 'auto-filter', icon: 'mdi:automatic' },
    { label: 'landing_page', value: 'landing-page', icon: 'bxs:door-open' },
    { label: 'change_password', value: 'change-password', icon: 'material-symbols:password' },
  ]

  const mainOptions: IOption<string>[] = [
    { label: 'enable', value: 'enable' },
    { label: 'disable', value: 'disable' },
  ]

  const dateRangeOptions: IOption<string>[] = [
    { label: '1_week', value: '1' },
    { label: '2_weeks', value: '2' },
    { label: '3_weeks', value: '3' },
    { label: '4_weeks', value: '4' },
  ]

  const sectionOptions: IOption<string>[] = [
    { label: 'health', value: 'health' },
    { label: 'yield', value: 'yield' },
    { label: 'pruning', value: 'pruning' },
    { label: 'land', value: 'land' },
  ]

  const pageOptions: IOption<string>[] = [
    { label: 'map', value: 'map' },
    { label: 'summary', value: 'summary' },
    { label: 'reporting', value: 'reporting' },
  ]

  const yieldPageOptions: IOption<string>[] = [
    { label: 'Summary', value: 'summary' },
    { label: 'Reporting', value: 'reporting' },
  ]

  const updateStorageAndState = (key: keyof UserSettingsStorage, value: string) => {
    setStorage({ ...storage, [key]: value as any })
    localStorage.setItem('user-settings', JSON.stringify({ ...storage, [key]: value }))
  }

  useEffect(() => {
    const data = localStorage.getItem('user-settings')
    if (data) setStorage(JSON.parse(data))
  }, [])

  useEffect(() => {
    if (storage.lpSection === 'yield' && storage.lpPage === 'map') updateStorageAndState('lpPage', 'reporting')
  }, [storage])

  return (
    <>
      <Typography color="grey.800" fontSize={20} textTransform="uppercase" pl={1}>
        {t('user_settings')}
      </Typography>
      <Divider />
      <Box display="flex">
        <Box width={260} pt={1} flexShrink={0}>
          {options.map((o) => (
            <Box
              key={o.value}
              p={1}
              display="flex"
              alignItems="center"
              gap={1}
              bgcolor={tab === o.value ? 'primary.light' : 'transparent'}
              color={tab === o.value ? '#262626' : 'grey.700'}
              onClick={() => setTab(o.value)}
              sx={{ cursor: 'pointer', borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
            >
              <Iconify icon={o.icon} width={26} />
              <label style={{ cursor: 'pointer' }}>{t(o.label)}</label>
            </Box>
          ))}
        </Box>
        <Box width="1px" bgcolor="primary.main" />
        <Box flexGrow={1} p={4}>
          {tab === 'auto-filter' && (
            <Box display="grid" gap={3}>
              <Box bgcolor="grey.100" p={1}>
                <Typography variant="caption" fontSize={13}>
                  {t('msg_auto_filter_settings')}
                </Typography>
              </Box>
              <UserSettingToggleGroup
                label={t('auto_filter')}
                options={mainOptions}
                value={storage.af}
                onChange={(value) => updateStorageAndState('af', value)}
              />
              <UserSettingToggleGroup
                label={t('date_range')}
                options={dateRangeOptions}
                value={storage.afDateRange}
                onChange={(value) => updateStorageAndState('afDateRange', value)}
                disabled={storage.af === 'disable'}
              />
            </Box>
          )}

          {tab === 'landing-page' && (
            <Box display="grid" gap={3}>
              <Box bgcolor="grey.100" p={1}>
                <Typography variant="caption" fontSize={13}>
                  {t('msg_landing_page_settings')}
                </Typography>
              </Box>
              <UserSettingToggleGroup
                label={t('custom_landing_page')}
                options={mainOptions}
                value={storage.lp}
                onChange={(value) => updateStorageAndState('lp', value)}
              />
              <UserSettingToggleGroup
                label={t('section')}
                options={sectionOptions}
                value={storage.lpSection}
                onChange={(value) => updateStorageAndState('lpSection', value)}
                disabled={storage.lp === 'disable'}
              />
              <UserSettingToggleGroup
                label={t('page')}
                options={storage.lpSection === 'yield' ? yieldPageOptions : pageOptions}
                value={storage.lpPage}
                onChange={(value) => updateStorageAndState('lpPage', value)}
                optionWidth={140}
                disabled={storage.lp === 'disable'}
              />
            </Box>
          )}

          {tab === 'change-password' && <ChangePassword />}
        </Box>
      </Box>
    </>
  )
}

export default SettingsPage
