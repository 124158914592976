import { Text, View } from '@react-pdf/renderer'
import { BlockReport, RowData } from '@typings/dtos/block-report/models'
import { fmtNumToThousandSepSingleDecOrZero } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import { s } from '../styles'
import SpurTableHead from './SpurTableHead'

type SpurBestWorstPrunedRowsProps = {
  report: BlockReport
}

const SpurBestWorstPrunedRows = ({ report }: SpurBestWorstPrunedRowsProps) => {
  const [bestPrunedRows, setBestPrunedRows] = useState<BlockReport['rowData']>([])
  const [worstPrunedRows, setWorstPrunedRows] = useState<BlockReport['rowData']>([])

  useEffect(() => {
    if (!report) return
    const { rowData } = report

    const tentHighestAndLowestBudsPerVineMeanRows = () => {
      if (!rowData.length) return { highest: [], lowest: [] }

      const sortedCaneData = rowData.slice().sort((a, b) => {
        const aValue = a.budsPerVineMean ?? Number.MAX_VALUE
        const bValue = b.budsPerVineMean ?? Number.MAX_VALUE
        return aValue - bValue
      })

      const sliceRange = Math.min(10, Math.floor(sortedCaneData.length / 2))
      const highest = sortedCaneData.slice(-sliceRange).reverse()
      const lowest = sortedCaneData.slice(0, sliceRange)

      return { highest, lowest }
    }

    const { highest, lowest } = tentHighestAndLowestBudsPerVineMeanRows()

    setBestPrunedRows(highest)
    setWorstPrunedRows(lowest)
  }, [report])

  const Row = ({ row, index }: { row: RowData; index: number }) => {
    return (
      <View key={index} style={{ height: 18, backgroundColor: index % 2 === 0 ? 'white' : '#F5F5F5', flexDirection: 'row' }}>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.rowNum)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.budsPerVineMean)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.spursPerVineMean)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.budsPerSpurPerVineMean)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.missingCordon1)}</Text>
        </View>
        <View style={s.row}>
          <Text>{fmtNumToThousandSepSingleDecOrZero(row.missingCordon2)}</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={{ width: '100%' }}>
      <Text style={{ marginBottom: 2 }}>ROWS WITH HIGHEST BUDS PER VINE (MEAN)</Text>
      <SpurTableHead />
      {bestPrunedRows.map((row, index) => (
        <Row key={index} row={row} index={index} />
      ))}
      <View style={{ height: 30 }} />
      <Text style={{ marginBottom: 2 }}>ROWS WITH LOWEST BUDS PER VINE (MEAN)</Text>

      <SpurTableHead />
      {worstPrunedRows.map((row, index) => {
        return <Row key={index} row={row} index={index} />
      })}
    </View>
  )
}

export default SpurBestWorstPrunedRows
