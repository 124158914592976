import useVineyardStore from '@stores/vineyard'
import { t } from 'i18next'
import { useShallow } from 'zustand/react/shallow'

type DatagridNoRowsOverlayProps = {
  data: any[]
  showNothing?: boolean
}

const DatagridNoRowsOverlay = ({ data, showNothing }: DatagridNoRowsOverlayProps) => {
  const [selectedBlocks] = useVineyardStore(useShallow((s) => [s.selectedBlocks]))

  if (showNothing) {
    return <div />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        fontFamily: 'monospace',
        color: '#A8A29E',
      }}
    >
      {selectedBlocks && selectedBlocks.length > 0 && data.length === 0 ? (
        <span
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, maxWidth: 650, textAlign: 'center' }}
        >
          <svg width="65" height="52" viewBox="0 0 65 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.16667 0C0.969557 0 0 0.969557 0 2.16667V52H65V2.16667C65 0.969557 64.0304 0 62.8333 0H2.16667ZM28.1667 4.33333C29.3638 4.33333 30.3333 5.30289 30.3333 6.5C30.3333 7.69711 29.3638 8.66667 28.1667 8.66667C26.9696 8.66667 26 7.69711 26 6.5C26 5.30289 26.9696 4.33333 28.1667 4.33333ZM19.5 4.33333C20.6971 4.33333 21.6667 5.30289 21.6667 6.5C21.6667 7.69711 20.6971 8.66667 19.5 8.66667C18.3029 8.66667 17.3333 7.69711 17.3333 6.5C17.3333 5.30289 18.3029 4.33333 19.5 4.33333ZM10.8333 4.33333C12.0304 4.33333 13 5.30289 13 6.5C13 7.69711 12.0304 8.66667 10.8333 8.66667C9.63622 8.66667 8.66667 7.69711 8.66667 6.5C8.66667 5.30289 9.63622 4.33333 10.8333 4.33333ZM62.8333 49.8333H2.16667V13H42.6511L50.2348 7.58333H62.834L62.8333 49.8333Z"
              fill="#D6D3D1"
            />
            <path
              d="M32.5 17C24.4912 17 18 23.2679 18 31C18 38.7321 24.4917 45 32.5 45C40.5083 45 47 38.7321 47 31C47 23.2679 40.5083 17 32.5 17ZM39.5996 34.8074L36.4457 37.8525L32.5 34.0455L28.557 37.8525L25.4032 34.8074L29.3462 31.0004L25.4032 27.1934L28.557 24.1483L32.5 27.9553L36.443 24.1483L39.5968 27.1934L35.6539 31.0004L39.5996 34.8074Z"
              fill="#D6D3D1"
            />
          </svg>

          {t('looks_like_no_data')}
        </span>
      ) : (
        <span style={{ position: 'relative', fontSize: 15 }}>
          {t('please_select_block')}
          <svg
            width="395"
            height="199"
            viewBox="0 0 395 199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute', bottom: 30, left: 0, translate: '-50% 0' }}
          >
            <path
              d="M15.42 19.7493C15.42 19.7493 8.46662 14.2584 2.16897 15.2491C-4.12868 16.2397 17.4435 9.01198 17.3086 3.58792"
              stroke="#D6D3D1"
              strokeWidth="2"
            />
            <path d="M10.5 13.5C344 -20 399.9 193.9 393.5 197.5" stroke="#D6D3D1" strokeWidth="2" />
          </svg>
        </span>
      )}
    </div>
  )
}

export default DatagridNoRowsOverlay
