import grey from '@mui/material/colors/grey'
import { useTheme } from '@mui/material/styles'
import useVineyardStore from '@stores/vineyard'
import { FilterToggle } from '@typings/common'
import { CordonCountEnum, PostMaterialEnum, VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { MAP_CLR } from '@utils/constants'
import { t } from 'i18next'
import { CSSProperties, Dispatch, Fragment, SetStateAction, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import MapFilterToggleButton from '../MapFilterToggleButton'

export type PostsAndVinesUnion = 'posts' | 'vines'

type PostsAndVineToggleProps = {
  showGraphics: string[]
  setShowGraphics: Dispatch<SetStateAction<string[]>>
}

const PostsAndVineToggle = ({ showGraphics, setShowGraphics }: PostsAndVineToggleProps) => {
  const { palette } = useTheme()
  const [activePruningStyle, landPostsOrVines, setLandPostsOrVines] = useVineyardStore(
    useShallow((s) => [s.activePruningStyle, s.landPostsOrVines, s.setLandPostsOrVines])
  )

  const btnStyle: CSSProperties = { width: 80, cursor: 'pointer', display: 'grid', placeItems: 'center' }
  const isVinesActive = landPostsOrVines === 'vines'
  const css = `.toggle-btn:hover { background-color: #F5F5F4; }`

  const updateShowGraphics = (key: string) => {
    if (showGraphics.includes(key)) setShowGraphics(showGraphics.filter((detection) => detection !== key))
    else setShowGraphics([...showGraphics, key])
  }

  const Divider = ({ width = 1 }: { width?: number }) => <div style={{ width, height: '100%', backgroundColor: grey[300] }} />

  const togglesByPruningStyle = () => {
    if (isVinesActive) {
      if (activePruningStyle === 'cane' || activePruningStyle === 'bow') return caneVinesToggle
      if (activePruningStyle === 'spur') return spurFilters
    }
    return postsFilters
  }

  useEffect(() => {
    setShowGraphics(isVinesActive ? vineDefaults : postDefaults)
  }, [landPostsOrVines])

  return (
    <div style={{ display: 'flex', backgroundColor: palette.background.paper }}>
      <style>{css}</style>
      {togglesByPruningStyle().map(({ symbol, label, value }, i) => (
        <Fragment key={label}>
          <MapFilterToggleButton
            width={value === `vine_${VineStateIdEnum.Dead}` ? 'max-content' : 66}
            isActive={showGraphics.includes(value)}
            symbol={symbol}
            label={label}
            onClick={() => updateShowGraphics(value)}
          />
          {togglesByPruningStyle().length > 1 && i <= togglesByPruningStyle().length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
      <div style={{ display: 'flex' }}>
        <Divider width={6} />
        <div style={{ display: 'grid', fontWeight: 500, fontSize: 13, height: '100%', textTransform: 'uppercase' }}>
          <span
            style={{
              ...btnStyle,
              backgroundColor: isVinesActive ? palette.primary.dark : '',
              color: isVinesActive ? '#FFF' : grey[700],
            }}
            onClick={() => setLandPostsOrVines('vines')}
            className="toggle-btn"
          >
            {t('vines')}
          </span>
          <span
            style={{
              ...btnStyle,
              backgroundColor: isVinesActive ? '' : palette.primary.dark,
              color: isVinesActive ? grey[700] : '#FFF',
            }}
            onClick={() => setLandPostsOrVines('posts')}
            className="toggle-btn"
          >
            {t('posts')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PostsAndVineToggle

const caneVinesToggle: FilterToggle[] = [
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.DEAD, borderRadius: 15 }} />,
    label: 'dead',
    value: `vine_${VineStateIdEnum.Dead}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.YOUNG, borderRadius: 15 }} />,
    label: 'young',
    value: `vine_${VineStateIdEnum.Young}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.MISSING, borderRadius: 15 }} />,
    label: 'missing',
    value: `vine_${VineStateIdEnum.Missing}`,
  },
]

const spurFilters: FilterToggle[] = [
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.DEAD, borderRadius: 15 }} />,
    label: `${t('dead')} / 0 ${t('cordon')}`,
    value: `vine_${VineStateIdEnum.Dead}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.YOUNG, borderRadius: 15 }} />,
    label: 'young',
    value: `vine_${VineStateIdEnum.Young}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.MISSING, borderRadius: 15 }} />,
    label: 'missing',
    value: `vine_${VineStateIdEnum.Missing}`,
  },

  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CORDON_1, borderRadius: 15 }} />,
    label: `1 ${t('cordon')}`,
    value: `cordon_${CordonCountEnum.Cordon1}`,
  },
]

const postsFilters: FilterToggle[] = [
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.METAL, borderRadius: 15 }} />,
    label: 'metal',
    value: `post_${PostMaterialEnum.Metal}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.WOOD, borderRadius: 15 }} />,
    label: 'wood',
    value: `post_${PostMaterialEnum.Wood}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.PLASTIC, borderRadius: 15 }} />,
    label: 'plastic',
    value: `post_${PostMaterialEnum.Plastic}`,
  },
  {
    symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.MISSING, borderRadius: 15 }} />,
    label: 'missing',
    value: `post_missing`,
  },
]

const postDefaults = [`post_${PostMaterialEnum.Metal}`, `post_${PostMaterialEnum.Wood}`, `post_${PostMaterialEnum.Plastic}`]

const vineDefaults = [`vine_${VineStateIdEnum.Dead}`, `vine_${VineStateIdEnum.Missing}`, `vine_${VineStateIdEnum.Young}`]
