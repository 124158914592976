import PasswordTextField from '@components/form/PasswordTextField'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { UserApi } from '@services/api'
import useAuthStore from '@stores/auth'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { FormEvent, useState } from 'react'

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const { t } = useTranslate()
  const [user] = useAuthStore((s) => [s.user])
  const handleAxiosError = useHandleAxiosError()

  const { mutate, isPending } = useMutation({
    mutationKey: ['changePassword'],
    mutationFn: () => UserApi.changePassword({ email: user?.email || '', password: currentPassword, newPassword }),
    onSuccess: () => {
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
      enqueueSnackbar('Password changed successfully', { variant: 'success' })
    },
    onError: (error) => handleAxiosError(error),
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutate()
  }

  return (
    <Box component="form" onSubmit={handleSubmit} display="grid" maxWidth={400} gap={3}>
      <PasswordTextField
        required
        label={t('current_password')}
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <PasswordTextField
        required
        label={t('new_password')}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <PasswordTextField
        required
        label={t('confirm_new_password')}
        value={confirmPassword}
        error={confirmPassword !== newPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button type="submit" variant="contained" disabled={isPending || newPassword !== confirmPassword || !newPassword}>
        {t('change_password')}
      </Button>
    </Box>
  )
}

export default ChangePassword
