import CustomTableWrapper from '@components/containers/CustomTableWrapper'
import BarcodedRows from '@components/visualisations/BarcodedRows'
import { YieldReportsByBlock } from '@hooks/useGetYieldBlockDataApi'
import useVineyardStore from '@stores/vineyard'
import { fmtNumToThousandSepSingleDecOrDash, formatISO_, formatToSingleDecimalIfNecessaryOrDash } from '@utils/utility-fns'
import { t } from 'i18next'
import { CSSProperties, Dispatch, SetStateAction } from 'react'
import { useShallow } from 'zustand/react/shallow'

type YieldTableProps = {
  block: YieldReportsByBlock
  setSelectedScan: Dispatch<SetStateAction<{ block: YieldReportsByBlock; scanIndex: number } | undefined>>
  setOpen: (value: SetStateAction<boolean>) => void
}

const YieldTable = ({ block, setSelectedScan, setOpen }: YieldTableProps) => {
  const [excludeNonCorrectedReports] = useVineyardStore(useShallow((s) => [s.excludeNonCorrectedReports]))

  const Cell = ({ color, count, mean, style }: { color: string; count?: number; mean?: number; style?: CSSProperties }) => {
    const s: CSSProperties = { flex: 1, backgroundColor: color, display: 'flex', alignItems: 'center', paddingLeft: 8 }
    return (
      <div
        style={{
          display: 'flex',
          height: 30,
          fontSize: 13,
          fontWeight: 600,
          gap: 1,
          fontFamily: 'monospace',
          color: '#525252',
          overflow: 'hidden',
          ...style,
        }}
      >
        <div style={s}>{fmtNumToThousandSepSingleDecOrDash(count)}</div>
        <div style={s}>
          {formatToSingleDecimalIfNecessaryOrDash(mean)}
          {/* <UpDownVisualIndicator change={report.visibleChange} /> */}
        </div>
      </div>
    )
  }

  return (
    <CustomTableWrapper blockName={block.blockName} vineyardName={block.vineyardName}>
      {block.reports
        .filter((r) => !excludeNonCorrectedReports || Object.keys(r.groundTruthData).length !== 0)
        .map((report, i) => {
          return (
            <div
              key={report.blockReportId}
              onClick={() => {
                setSelectedScan({ block, scanIndex: i })
                setOpen(true)
              }}
              style={{
                cursor: 'pointer',
                backgroundColor: '#FAFAFA',
                borderRadius: 4,
                border: '2px solid #F5F5F5',
              }}
              className="custom-table-col"
            >
              <div className="custom-table-head" style={{ padding: '8px 12px 12px 12px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingBottom: 2 }}>
                  <div style={{ fontSize: 13, fontWeight: 500, color: '#525252' }}>
                    {formatISO_(report.startDate)} &#8212; {formatISO_(report.endDate)}
                  </div>
                  {report.rowsScannedPct > 0 && <div style={{ fontSize: 12, color: '#737373' }}>{report.rowsScannedPct}%</div>}
                </div>
                <BarcodedRows
                  color="#737373"
                  rowsStartNumber={block.rowStart}
                  rowsEndNumber={block.rowEnd}
                  rowIndex={block.rowIndex}
                  rowsScanned={report.rowsScanned.map((row) => row.rowNum)}
                  style={{ borderRadius: 3, overflow: 'hidden' }}
                />
              </div>
              <div style={{ padding: '0 12px 12px 12px' }}>
                <div style={{ display: 'flex', fontSize: 10, fontWeight: 500, textAlign: 'start', opacity: 0.6, gap: 1 }}>
                  <div style={{ flex: 1, paddingLeft: 8 }}>{t('total')}</div>
                  <div style={{ flex: 1, paddingLeft: 8 }}>{t('per_vine_(mean)')}</div>
                </div>
                <Cell
                  color="#FEF08A"
                  count={report.visBunchCountTotal}
                  mean={report.visBunchesPerVineMean}
                  style={{ borderRadius: '3px 3px 0px 0px' }}
                />
                <Cell color="#BFDBFE" count={report.correctedBunchCountTotal} mean={report.correctedBunchesPerVineMean} />
                <Cell
                  color="#BBF7D0"
                  count={report.extrapolatedBunchCountTotal}
                  mean={report.extrapolatedBunchesPerVineMean}
                  style={{ borderRadius: '0px 0px 3px 3px' }}
                />
              </div>
            </div>
          )
        })}
    </CustomTableWrapper>
  )
}

export default YieldTable
