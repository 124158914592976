import SliderLegendChip from '@components/icons/SliderLegendChip'
import useVineyardStore from '@stores/vineyard'
import { BlockData } from '@typings/dtos/block-report/models'
import { MAP_CLR } from '@utils/constants'
import { useShallow } from 'zustand/react/shallow'

type LandReportCountsProps = {
  blockData: BlockData
}

const LandReportCounts = ({ blockData: b }: LandReportCountsProps) => {
  const [landPostsOrVines, activePruningStyle] = useVineyardStore(useShallow((s) => [s.landPostsOrVines, s.activePruningStyle]))

  return (
    <div style={{ display: 'flex', color: '#FFF', gap: 8 }}>
      {landPostsOrVines === 'vines' && (
        <>
          {activePruningStyle === 'spur' ? (
            <>
              <SliderLegendChip clr={MAP_CLR.DEAD} val={(b?.vDead || 0) + (b?.missingCordon2 || 0)} />
              <SliderLegendChip clr={MAP_CLR.YOUNG} val={b.vYoung} />
              <SliderLegendChip clr={MAP_CLR.MISSING} val={b.vMissing} />
              <SliderLegendChip clr={MAP_CLR.CORDON_1} val={b.missingCordon1} />
            </>
          ) : (
            <>
              <SliderLegendChip clr={MAP_CLR.DEAD} val={(b?.vDead || 0) + (b?.missingCordon2 || 0)} />
              <SliderLegendChip clr={MAP_CLR.YOUNG} val={b.vYoung} />
              <SliderLegendChip clr={MAP_CLR.MISSING} val={b.vMissing} />
            </>
          )}
        </>
      )}
      {landPostsOrVines === 'posts' && (
        <>
          <SliderLegendChip clr={MAP_CLR.METAL} val={b.pMetal} />
          <SliderLegendChip clr={MAP_CLR.WOOD} val={b.pWooden} />
          {/* <SliderLegendChip clr={MAP_CLR.PLASTIC} val={b.pPlastic} /> */}
          <SliderLegendChip clr={MAP_CLR.MISSING} val={b.pMissing} />
        </>
      )}
    </div>
  )
}

export default LandReportCounts
