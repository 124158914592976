import { BlockReport } from '@typings/dtos/block-report/models'
import { useMemo } from 'react'

export function useBlockDataHighlights({ data }: { data: BlockReport[] }) {
  const {
    totalDeadVines,
    totalMissingVines,
    totalYoungVines,
    totalNormalVines,
    totalMissingCordon1,
    totalMissingCordon2,
    totalVines,
    totalMetalPosts,
    totalWoodenPosts,
    totalPlasticPosts,
    totalMissingPosts,
    totalUnknownPosts,
  } = useMemo(() => {
    let totalDeadVines = 0
    let totalMissingVines = 0
    let totalVines = 0
    let totalYoungVines = 0
    let totalNormalVines = 0
    let totalMissingCordon1 = 0
    let totalMissingCordon2 = 0
    let totalMetalPosts = 0
    let totalWoodenPosts = 0
    let totalPlasticPosts = 0
    let totalMissingPosts = 0
    let totalUnknownPosts = 0

    for (const block of data) {
      const {
        vDead = 0,
        vMissing = 0,
        vNormal = 0,
        vYoung = 0,
        missingCordon1 = 0,
        missingCordon2 = 0,
        pMetal = 0,
        pWooden = 0,
        pPlastic = 0,
        pMissing = 0,
        pUnknown = 0,
      } = block.blockData || {}
      // If any of the properties (vDead, vMissing, vNormal, vYoung) are undefined or not available,
      // the default value of 0 will be used in the calculations.
      totalDeadVines += vDead
      totalMissingVines += vMissing
      totalYoungVines += vYoung
      totalNormalVines += vNormal
      totalMissingCordon1 += missingCordon1
      totalMissingCordon2 += missingCordon2
      totalVines += vNormal + vYoung + vMissing + vDead
      totalMetalPosts += pMetal
      totalWoodenPosts += pWooden
      totalPlasticPosts += pPlastic
      totalMissingPosts += pMissing
      totalUnknownPosts += pUnknown
    }

    return {
      totalDeadVines,
      totalMissingVines,
      totalYoungVines,
      totalNormalVines,
      totalMissingCordon1,
      totalMissingCordon2,
      totalVines,
      totalMetalPosts,
      totalWoodenPosts,
      totalPlasticPosts,
      totalMissingPosts,
      totalUnknownPosts,
    }
  }, [data])

  return {
    totalDeadVines,
    totalMissingVines,
    totalYoungVines,
    totalNormalVines,
    totalMissingCordon1,
    totalMissingCordon2,
    totalVines,
    totalMetalPosts,
    totalWoodenPosts,
    totalPlasticPosts,
    totalMissingPosts,
    totalUnknownPosts,
  }
}
