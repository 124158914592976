import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useSetStateLandVineDataset } from '@hooks/land/useSetStateLandVineDataset'
import { LandDataset, useSortLandDataset } from '@hooks/land/useSortLandDataset'
import { useBlockDataHighlights } from '@hooks/useBlockDataHighlights'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { MAP_CLR } from '@utils/constants'
import {
  LandSpurPost_TooltipProperties,
  LandSpurVine_TooltipProperties,
  labelFormatter_Land,
  tooltipFormatter,
} from '@utils/echarts'
import { LandSpur_VineStatsSortOptions } from '@utils/options'
import { numberFmtThousandSeparator, uniqueBlockNames } from '@utils/utility-fns'
import { useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'
import { LandSummarySectionProps } from './LandCaneSection'

const LandSpurSection = ({ data }: LandSummarySectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [vineStatsDataset, setVineStatsDataset] = useState<LandDataset>({
    id: 'land-spur-vine-stats-dataset',
    dimensions: ['blockName', 'missingVines', 'missingCordon1', 'missingCordon2', 'youngVines'],
    source: [],
  })

  const [postStatsDataset, setPostStatsDataset] = useState<LandDataset>({
    id: 'land-spur-post-stats-dataset',
    dimensions: ['blockName', 'pMissing', 'pMetal', 'pWooden', 'pPlastic', 'pUnknown'],
    source: [],
  })

  const { t } = useTranslate()

  useSortLandDataset(setVineStatsDataset, activeSort)

  useSetStateLandVineDataset(setVineStatsDataset, data)
  useSetStateLandVineDataset(setPostStatsDataset, data)

  const {
    totalYoungVines,
    totalMissingVines,
    totalVines,
    totalMissingCordon1,
    totalMissingCordon2,
    totalMissingPosts,
    totalMetalPosts,
    totalWoodenPosts,
    totalPlasticPosts,
    totalUnknownPosts,
  } = useBlockDataHighlights({
    data,
  })

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent data={uniqueBlockNames(data)} />}
          tooltipInfo="pruning_page.summary.tooltip_showing_stats_for"
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="total" color="info.dark" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalYoungVines)} label="young_vines" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingCordon1)} label="1_missing_cordon" color="error.main" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingCordon2)} label="2_missing_cordons" color="error.main" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
        <HighlightsInfoWrapper
          label="posts"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingPosts)} label="missing" color="error.main" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalMetalPosts)} label="metal" />
            <HighlightInfo value={numberFmtThousandSeparator(totalWoodenPosts)} label="wooden" />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={numberFmtThousandSeparator(totalPlasticPosts)} label="plastic" />
            <HighlightInfo value={numberFmtThousandSeparator(totalUnknownPosts)} label="unknown" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SummaryTileWrapper
        label="vine_stats"
        width="100%"
        height="100%"
        tooltipMsg="land_page.summary.spur.tooltip_vine_stats"
        headerComponent={
          vineStatsDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-vine-stats"
              menuItems={LandSpur_VineStatsSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={vineStatsDataset}
            legend={{
              show: true,
              formatter: (name) => {
                // add an underscore before capital letters or numbers and convert to lowercase
                const key = name.replace(/([A-Z0-9])/g, '_$1').toLowerCase()
                return t(key)
              },
            }}
            color={['#F87171', '#FB923C', '#FDE047', '#60A5FA']}
            tooltipFormatter={(params) => tooltipFormatter(params, t, LandSpurVine_TooltipProperties)}
            hideLabelValueBelow={0.4}
            yAxisCategoryLabelFormatter={labelFormatter_Land}
            formatLabelToPct={false}
          />
        </Box>
      </SummaryTileWrapper>
      <SummaryTileWrapper
        label="post_stats"
        width="100%"
        height="100%"
        // headerComponent={
        //   vineStatsDataset.source.length > 1 && (
        //     <SortBlocksMenu
        //       id="sort-vine-stats"
        //       menuItems={LandSpur_VineStatsSortOptions}
        //       value={activeSort}
        //       onClick={(val) => setActiveSort(val)}
        //       menuArrowPositionRight={110}
        //     />
        //   )
        // }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={postStatsDataset}
            legend={{
              show: true,
              formatter: (name) => {
                // remove first character to get the translation key
                const key = name.slice(1).toLowerCase()
                return t(key)
              },
            }}
            color={[MAP_CLR.MISSING, MAP_CLR.METAL, MAP_CLR.WOOD, MAP_CLR.PLASTIC, '#000000']}
            tooltipFormatter={(params) => tooltipFormatter(params, t, LandSpurPost_TooltipProperties, '#FFFFFF')}
            hideLabelValueBelow={0.4}
            yAxisCategoryLabelFormatter={labelFormatter_Land}
            formatLabelToPct={false}
          />
        </Box>
      </SummaryTileWrapper>
    </>
  )
}

export default LandSpurSection
