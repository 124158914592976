import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import Paper from '@mui/material/Paper'

import DatagridNoRowsOverlay from '@components/data-display/DatagridNoRowsOverlay'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useGetRowBlockReportsApi } from '@hooks/useGetRowBlockReportsApi'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useVineyardStore from '@stores/vineyard'
import { ReportTypeEnum } from '@typings/dtos/block-report/enums'
import { pinnedColumns } from '@utils/constants'
import { yieldCols, yieldColsGrouping } from '@utils/datagrid-cols/yield-cols'
import { format } from 'date-fns'
import { useShallow } from 'zustand/react/shallow'

const YieldReportingPage = () => {
  const { localeText } = useDataGridLocaleText()
  const { data, isFetching } = useGetRowBlockReportsApi(ReportTypeEnum.Yield)
  const [excludeNonCorrectedReports] = useVineyardStore(useShallow((s) => [s.excludeNonCorrectedReports]))

  return (
    <Paper sx={{ borderRadius: 1, height: 'calc(100vh - 90px)', overflow: 'hidden' }} variant="outlined">
      <DataGridPro
        rows={data.filter((r) => !excludeNonCorrectedReports || r.correctedBunchCountTotal !== null)}
        columns={yieldCols()}
        columnGroupingModel={yieldColsGrouping()}
        loading={isFetching}
        initialState={pinnedColumns}
        getRowId={(r) => r.id + r.blockId.toString() + r.rowId.toString()}
        columnHeaderHeight={40}
        disableColumnMenu
        hideFooter
        slots={{
          noRowsOverlay: () => (
            <DatagridNoRowsOverlay
              data={data}
              showNothing={
                data.length > 0 &&
                data.filter((r) => !excludeNonCorrectedReports || r.correctedBunchCountTotal !== null).length === 0
              }
            />
          ),
          toolbar: () => (
            <CustomGridToolbar
              showExcludeReportsCheckbox
              fileName={`Yield Report - ${format(new Date(), 'dd MMM yyyy')}`}
              rowCount={data.filter((r) => !excludeNonCorrectedReports || r.correctedBunchCountTotal !== null).length}
            />
          ),
        }}
        // slotProps={{ toolbar: { fileName: `Yield Report - ${format(new Date(), 'dd MMM yyyy')}`, rowCount: data?.length } }}
        localeText={localeText}
      />
    </Paper>
  )
}

export default YieldReportingPage
