// export const VISUAL_VARIABLES = [
//   {
//     type: 'size',
//     valueExpression: '$view.scale',
//     stops: [
//       { size: 78, value: 40 },
//       { size: 45, value: 100 },
//       { size: 23, value: 200 },
//       { size: 13, value: 400 },
//       { size: 11, value: 800 },
//       { size: 9, value: 1500 },
//       { size: 6, value: 5000 },
//       { size: 4, value: 10000 },
//     ],
//   } as __esri.SizeVariableProperties,
// ]

export const VISUAL_VARIABLES = [
  {
    type: 'size',
    valueExpression: '$view.scale',
    stops: [
      { size: 45, value: 40 },
      { size: 28, value: 100 },
      { size: 16, value: 200 },
      { size: 10, value: 400 },
      { size: 8, value: 800 },
      { size: 6, value: 1500 },
      { size: 4, value: 5000 },
      { size: 3, value: 10000 },
    ],
  } as __esri.SizeVariableProperties,
]
