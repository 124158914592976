import dictionaryGb from './gb.json'

const translationUS = {
  ...dictionaryGb,
  posts: 'Stakes',
  post_status: 'Stakes Status',
  total_posts: 'Total Stakes',
  missing_posts: 'Missing Stakes',
  metal_posts: 'Metal Stakes',
  wooden_posts: 'Wooden Stakes',
  unknown_posts: 'Unknown Stakes',
  tooltip_posts_pie: 'This is the total number of stakes detected in all the selected blocks.',
  tooltip_posts_per_block_barchart: 'This is the total number of stakes detected in the block.',
  post_stats: 'Stakes Stats',
}

export default translationUS
