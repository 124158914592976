import { Text, View } from '@react-pdf/renderer'

const SpurTableHead = () => {
  const THCell = ({ title }: { title: string }) => (
    <View
      style={{
        flex: 1,
        height: 28,
        fontSize: 9,
        position: 'relative',
        backgroundColor: '#71717A',
        color: '#FFFFFF',
        paddingLeft: 4,
        justifyContent: 'center',
      }}
    >
      <Text style={{ flexWrap: 'wrap' }} break={false} wrap={false}>
        {title}
      </Text>
    </View>
  )

  return (
    <View style={{ flexDirection: 'row', width: '100%', gap: 1 }}>
      <THCell title="Row" />
      <THCell title="Buds Per Vine (Mean)" />
      <THCell title="Spurs Per Vine (Mean)" />
      <THCell title="Buds Per Spur Per Vine (Mean)" />
      <THCell title={`1 Missing ${'\n'}Cordon`} />
      <THCell title={`2 Missing ${'\n'}Cordons`} />
    </View>
  )
}

export default SpurTableHead
