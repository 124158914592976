import SliderLegendChip from '@components/icons/SliderLegendChip'
import useVineyardStore from '@stores/vineyard'
import { BlockData } from '@typings/dtos/block-report/models'
import { MAP_CLR } from '@utils/constants'
import { useShallow } from 'zustand/react/shallow'

type PruningReportCountsProps = {
  blockData: BlockData
}

const PruningReportCounts = ({ blockData: b }: PruningReportCountsProps) => {
  const [activePruningStyle] = useVineyardStore(useShallow((s) => [s.activePruningStyle]))

  return (
    <div style={{ display: 'flex', color: '#FFF', gap: 8 }}>
      {activePruningStyle === 'cane' && (
        <>
          <SliderLegendChip clr={MAP_CLR.CANE_0} val={b.vDead} />
          <SliderLegendChip clr={MAP_CLR.CANE_1} val={b.cane1} />
          <SliderLegendChip clr={MAP_CLR.CANE_2} val={b.cane2} />
          <SliderLegendChip clr={MAP_CLR.CANE_3} val={b.cane3} />
          <SliderLegendChip clr={MAP_CLR.YOUNG} val={b.vYoung} />
          <SliderLegendChip clr={MAP_CLR.MISSING} val={b.vMissing} />
        </>
      )}
      {activePruningStyle === 'spur' && (
        <>
          <SliderLegendChip clr={MAP_CLR.DEAD} val={(b?.vDead || 0) + (b?.missingCordon2 || 0)} />
          <SliderLegendChip clr={MAP_CLR.CORDON_1} val={b.missingCordon1} />
          <SliderLegendChip clr={MAP_CLR.YOUNG} val={b.vYoung} />
          <SliderLegendChip clr={MAP_CLR.MISSING} val={b.vMissing} />
        </>
      )}
      {activePruningStyle === 'bow' && (
        <>
          <SliderLegendChip clr={MAP_CLR.YOUNG} val={b.vYoung} />
          <SliderLegendChip clr={MAP_CLR.MISSING} val={b.vMissing} />
        </>
      )}
    </div>
  )
}

export default PruningReportCounts
