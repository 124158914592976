import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point'
import Polyline from '@arcgis/core/geometry/Polyline'
import useReportsClosestToTargetDate from '@hooks/useReportsClosestToTargetDate'
import { geojsonToArcGIS } from '@terraformer/arcgis'
import { BlockReportsByBlock } from '@typings/component'
import { VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { MapVineDTO } from '@typings/dtos/block-report/map-data'
import { useCallback, useEffect } from 'react'
import { updateRowAndVineFeatureLayers } from '../utils'

export type UseAddPruningVineAndRowGraphicsProps = {
  targetDate: number
  reports: BlockReportsByBlock[] | undefined
  showGraphics: string[]
}

export function useAddPruningVineAndRowGraphics({ targetDate, reports, showGraphics }: UseAddPruningVineAndRowGraphicsProps) {
  const reportsClosestToTargetDate = useReportsClosestToTargetDate(reports, targetDate)

  const addGraphics = useCallback(async () => {
    if (!reportsClosestToTargetDate) return

    const vineGraphics: Graphic[] = []
    const rowGraphics: Graphic[] = []

    reportsClosestToTargetDate.forEach((report) => {
      if (!report) return

      const getVineType = (vine: MapVineDTO) => {
        //show only vine which are in showGraphics array
        if (vine.state === VineStateIdEnum.Missing || vine.state === VineStateIdEnum.Young) return `vine_${vine.state}`
        else if (vine.caneCount === 0 || vine.cordonCount === 0) return `vine_${VineStateIdEnum.Dead}`
        else if (vine.caneCount) return `cane_${vine.caneCount}`
        else if (vine.cordonCount) return `cordon_${vine.cordonCount}`
        return ''
      }

      const caneTarget = report.blockData.caneTarget

      if (!report.mapData || typeof report.mapData === 'string') return

      const rowsAsMap = new Map(report.rowData.map((row) => [row.rowNum, row]))

      report.mapData.forEach((data) => {
        for (const vine of data.vines) {
          if (vine.badQuality) continue
          if (showGraphics.length === 0 || !showGraphics.includes(getVineType(vine)) || vine.caneCount > caneTarget) continue

          const details = {
            id: vine.id,
            blockName: report.blockName,
            rowNum: data.rowNum,
            coordinates: vine.c,
            scanDate: rowsAsMap.get(data.rowNum)?.scanDate,
            markerType: getVineType(vine),
          }

          const graphic = new Graphic({
            geometry: new Point({ x: vine.c[0], y: vine.c[1], spatialReference: { wkid: 4326 } }),
            attributes: { id: vine.id, markerType: getVineType(vine), details: JSON.stringify(details) },
          })
          vineGraphics.push(graphic)
        }

        const rowGraphic = new Graphic({
          attributes: { id: data.rowId },
          geometry: new Polyline(geojsonToArcGIS(data.rowGeometry)),
        })

        rowGraphics.push(rowGraphic)
      })
    })

    try {
      updateRowAndVineFeatureLayers(vineGraphics, rowGraphics)
    } catch (error) {
      console.error('Error adding graphics:', error)
    }
  }, [reportsClosestToTargetDate, showGraphics])

  useEffect(() => {
    addGraphics()
  }, [addGraphics])
}
